import React from 'react';
import Header from '../../components/Header';
import NavBar from '../../components/NavBar';
import HeroSection from '../../components/HeroSection';
import LoginForm from '../../components/LoginForm';
import { TableSection } from '../../components/TableSection';
import { AuthProvider } from '../../contexts/AuthContext';

function MainPage() {
    return (
      <AuthProvider>
          <div>
              <Header />
              <NavBar />
              <HeroSection />
              <LoginForm />
              <TableSection />
          </div>
      </AuthProvider>
    );
}

export default MainPage;
