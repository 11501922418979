import React from 'react';
import MainPage from './views/MainPage';

function App() {
    return (
        <div className="app-container">

            <div className="content-container">
                <MainPage />
            </div>
        </div>
    );
}

export default App;
