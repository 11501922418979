import React from 'react';
import ChallengesRow from './ChallengesRow';
import './styles.css';

function ChallengesTable({ challenges, currentPage, resultsPerPage, onPageChange, totalResults, setSortOption, setSearchTerm }) {
    const totalPages = Math.ceil(totalResults / resultsPerPage);

    console.log("Filtered Challenges:", challenges);
    console.log("Current Page:", currentPage);
    console.log("Total Pages:", totalPages);
    console.log("Results Per Page:", resultsPerPage);

    const fromIndexResults = Math.min((currentPage - 1) * resultsPerPage + 1, totalResults);
    const toIndexResults = Math.min(currentPage * resultsPerPage, totalResults);

    return (
        <div className="table-wrapper">
            <div className="filter-sort-section">
                <div className="search-sort-controls">
                    <select onChange={e => setSortOption(e.target.value)}>
                        <option value="">Sort by...</option>
                        <option value="name">Name</option>
                        <option value="level">Level</option>
                        <option value="tournament_level">Tournament Level</option>
                        <option value="type">Type</option>
                        <option value="subtype">Subtype</option>
                    </select>
                    <div className="a-size-base a-color-base">{fromIndexResults}-{toIndexResults} of Total Results: {totalResults}</div>

                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>


            <table className="challenges-table">
                <thead className="table-header a-size-base a-color-base">
                    <tr>
                        <th>Name</th>
                        <th>Challenge Level</th>
                        <th>Tournament Level</th>
                        <th>Challenge Type</th>
                        <th>Challenge Subtype</th>
                    </tr>
                </thead>
                <tbody className="table-body">
                    {challenges.map(challenge => (
                        <ChallengesRow key={challenge.id} challenge={challenge} />
                    ))}
                </tbody>
            </table>

            <div className="pagination-controls">
                <button onClick={() => onPageChange(1)} disabled={currentPage === 1}>
                    First
                </button>
                <button onClick={() => onPageChange(Math.max(currentPage - 1, 1))} disabled={currentPage === 1}>
                    Prev
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage === totalPages}>
                    Next
                </button>
                <button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>
                    Last
                </button>
            </div>
        </div>
    );
}

export default ChallengesTable;
