// src/components/GoogleSignInButton.js

import React, { useContext, useEffect } from 'react';
import { initializeGoogleSignIn, renderGoogleButton } from '../../services/AuthService';
import { AuthContext } from '../../contexts/AuthContext';

function GoogleSignInButton() {
  const { setIsAuthenticated, setUser } = useContext(AuthContext);
  useEffect(() => {
    initializeGoogleSignIn((jwtToken, user) => {

      try {
        console.log("JWT Token received:", jwtToken);
        // Handle post-login actions here, if needed
        // Save the token in local storage or context or state
        localStorage.setItem('token', jwtToken);
        localStorage.setItem('user', JSON.stringify(user));
        setIsAuthenticated(true);
        setUser(user);

      } catch (error) {
        // Handle error
        console.error(error);
      }
    });
    renderGoogleButton();
  }, []);

  return <div id="google-signin-button"></div>;
}

export default GoogleSignInButton;
