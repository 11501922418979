import React from 'react';
import './styles.css';

function ChallengesRow({ challenge }) {
  return (
    <tr>
      <td><span className="a-size-base a-color-base"><a href={`/pdfs/${challenge.name}.pdf`} target="_blank">
  {decodeURIComponent(challenge.name)}</a></span>
      </td>

      <td><span className="a-size-base a-color-base">{challenge.level}</span></td>
      <td><span className="a-size-base a-color-base">{challenge.tournament_level}</span></td>
      <td><span className="a-size-base a-color-base">{challenge.type}</span></td>
      <td><span className="a-size-base a-color-base">{challenge.subtype}</span></td>
    </tr>
  );
}

export default ChallengesRow;
