import React from 'react';
import './styles.css';

function FilterChallengeSidebar({ filters, setFilters }) {
    const levels = ["Entry", "Rising Stars", "Advanced", "Both"];
    const types = ["Performance", "Task", "Combination"];
    const subtypes = ["Storytelling", "Characterization", "Communication", "Bridge", "Tower", "Move Balls", "Device", "Invention", "Sales"];

    return (
        <div className="sidebar">
            <div>
                <div id="p_85-title" className="a-section a-spacing-small">
                    <span className="a-size-base a-color-base bold-weight-text">Challenge Level</span>
                </div>
                {levels.map(level => (
                    <div key={level}>
                        <input
                            type="checkbox"
                            checked={filters.level && filters.level.includes(level)}
                            onChange={() => {
                                const currentFilters = filters.level || [];
                                if (currentFilters.includes(level)) {
                                    setFilters({ ...filters, level: currentFilters.filter(l => l !== level) });
                                } else {
                                    setFilters({ ...filters, level: [...currentFilters, level] });
                                }
                            }}
                        />
                        <i className="a-icon a-icon-checkbox"></i>
                        <span className="a-label a-checkbox-label"></span>
                        <span className="a-size-base a-color-base">{level}</span>
                    </div>
                ))}
            </div>

            <hr/>

            <div>
                <div id="p_85-title" className="a-section a-spacing-small">
                    <span className="a-size-base a-color-base bold-weight-text">Challenge Type</span>
                </div>
                {types.map(type => (
                    <div key={type}>
                        <input
                            type="checkbox"
                            checked={filters.type && filters.type.includes(type)}
                            onChange={() => {
                                const currentFilters = filters.type || [];
                                if (currentFilters.includes(type)) {
                                    setFilters({ ...filters, type: currentFilters.filter(t => t !== type) });
                                } else {
                                    setFilters({ ...filters, type: [...currentFilters, type] });
                                }
                            }}
                        />
                        <span className="a-size-base a-color-base">{type}</span>
                    </div>
                ))}
            </div>

            <hr/>
            <div>
                <div id="p_85-title" className="a-section a-spacing-small">
                    <span className="a-size-base a-color-base bold-weight-text">Challenge Subtype</span>
                </div>
                {subtypes.map(subtype => (
                    <div key={subtype}>
                        <input
                            type="checkbox"
                            checked={filters.subtype && filters.subtype.includes(subtype)}
                            onChange={() => {
                                const currentFilters = filters.subtype || [];
                                if (currentFilters.includes(subtype)) {
                                    setFilters({ ...filters, subtype: currentFilters.filter(t => t !== subtype) });
                                } else {
                                    setFilters({ ...filters, subtype: [...currentFilters, subtype] });
                                }
                            }}
                        />
                        <span className="a-size-base a-color-base">{subtype}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FilterChallengeSidebar;
