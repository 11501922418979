// src/services/AuthService.js

// Standard login function with username and password
export async function login(username, password) {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/token/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();
    if (response.ok) {
      // Return both access_token and user information
      return {
        token: data.access_token,
        user: data.user // assuming 'user' contains the user information
      };
    } else {
      throw new Error(data.detail);
    }
  } catch (error) {
    throw error;
  }
}

export function initializeGoogleSignIn(callback) {
  const waitForGoogle = setInterval(() => {
    if (window.google && window.google.accounts) {
      clearInterval(waitForGoogle);
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response) => {
          const jwtToken = response.credential;
          const userProfile = JSON.parse(atob(response.credential.split('.')[1])); // Decode token for user info
          const user = userProfile?.name;
          console.log('[AuthService:initializeGoogleSignIn]',userProfile);
          callback(jwtToken, user);
        }
      });
    }
  }, 100);  // Checks every 100ms for GIS library
}


// Handle Google ID token and exchange it with your backend
async function handleGoogleCredentialResponse(response, callback) {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const idToken = response.credential;

    const apiResponse = await fetch(`${apiUrl}/token/google`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: idToken }),
    });

    const data = await apiResponse.json();
    console.log("Backend response data:", data);  // Check if access_token is present

    if (apiResponse.ok) {
      if (callback) callback(data.access_token);
      return {
        token: data.access_token,
        user: data.user // assuming 'user' contains the user information
      };
    } else {
      throw new Error(data.detail);
    }
  } catch (error) {
    console.error('Google login service failed', error);
    throw error;
  }
}

// Render Google Sign-In button
export function renderGoogleButton() {
  const waitForGoogle = setInterval(() => {
    if (window.google && window.google.accounts) {
      clearInterval(waitForGoogle);
      window.google.accounts.id.renderButton(
        document.getElementById("google-signin-button"),
        { theme: "outline", size: "large" }
      );
    }
  }, 100);
}


// Google Sign-Out using Google Identity Services
export function googleSignOut() {
  window.google.accounts.id.disableAutoSelect();  // Clears Google's auto sign-in session
  // Also clear your local storage/session storage if needed
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}
