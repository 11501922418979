import './styles.css';

const Header = () => {

    return (
        <header className="header">
            <div className="header-logo">
                <img src="/images/SteamworksAILogo_Silver.png" alt="Logo" />
            </div>
            <div className="header-title">
                <img src="/images/SteamworksAITitle_Silver.png" alt="Steamworks" />
            </div>
        </header>
    );
};

export default Header;
