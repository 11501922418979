// src/services/InstantChallengesService.js
// Data (specifically, challenge.name) needs to be encoded because there could
// be question marks in the name.

export async function fetchChallengesDetails() {
  try {
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/challenges/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.ok) {
      const data = await response.json();

      // Encode challenge names
      const encodedData = data.map(challenge => {
        return {
          ...challenge,
          name: encodeURIComponent(challenge.name)
        };
      });

      console.log(encodedData);
      return encodedData;
    } else {
      throw new Error('Failed to fetch instant challenges details');
    }
  } catch (error) {
    throw error;
  }
}
