import React, { useState, useEffect, createContext } from 'react';
import { initializeGoogleSignIn, googleSignOut, renderGoogleButton } from '../services/AuthService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  // Initialize authentication state from localStorage when component mounts
  useEffect(() => {
    const token = localStorage.getItem('token');

    const storedUser = JSON.parse(localStorage.getItem('user'));
    console.log('[AuthContext:useEffect]', user);
    console.log('[AuthContext:useEffect]', isAuthenticated);
    if (storedUser && token) {
      setUser(storedUser); // Set user from localStorage
      setIsAuthenticated(true); // User is authenticated
    }
  }, []);

  const loginWithGoogle = () => {
    // Initialize Google Sign-In and handle token response
    initializeGoogleSignIn(async (jwtToken, user) => {
      if (jwtToken) {
        localStorage.setItem('token', jwtToken);
        localStorage.setItem('user', JSON.stringify(user));
        setIsAuthenticated(true);  // Set authenticated state
        setUser(user);
      }
    });
    renderGoogleButton();
  };


  const logout = () => {
    // Clear JWT token and user info
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);

    // Call Google Sign-Out
    googleSignOut();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, loginWithGoogle, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
