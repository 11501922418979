import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { login } from '../../services/AuthService';
import GoogleSignInButton from './GoogleSignInButton';
import './styles.css';

function LoginForm() {
  const { isAuthenticated, setIsAuthenticated, user, setUser, logout } = useContext(AuthContext);

  const [username, setUsername] = useState(''); // Manage username input field separately
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isAuthenticated && user) {
      console.log('[LoginForm:useEffect]',user)
      setUsername(user.name || ''); // Set the username input field from AuthContext user
    } else {
      console.log('[LoginForm:useEffect] No valid user or user.name yet.');
    }
  }, [isAuthenticated, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assuming your `login` function returns both token and user information
      const { token, user } = await login(username, password);

      // Save the token in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user)); // Store the full user object

      // Update context with the authenticated state and user
      setIsAuthenticated(true);
      console.log('[LoginForm:handleSubmit]',user)
      // setUsername(user);
      setUser(user);  // Update the context user immediately

    } catch (error) {
      console.error('Login failed:', error);
    }
  };


  // If the user is authenticated, do not show the login form.
  if (isAuthenticated) {

    console.log('[LoginForm:isAuthenticated]',user)
    return (
      <>
      </>
    );
  }

  return (
    <div className="main-content">
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)} // Handle username input
            placeholder="Username"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
      <div className="login-line"></div>
      <label className="login-or">
        <span>or</span>
      </label>
      <div className="login-line"></div>
      <div className="social-login">
        <div className="social-button">
          <GoogleSignInButton />
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
