import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { googleSignOut } from '../../services/AuthService';
import './styles.css';

const NavBar = () => {
  const { user, logout, isAuthenticated } = useContext(AuthContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const handleLogout = () => {
    googleSignOut(); // Sign out from Google if integrated
    logout(); // Clear authentication context
  };
  // If the user ism not authenticated, do not show the nav bar.
  if (!isAuthenticated) {
    return (
      <>
      </>
    );
  }

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="navbar-title"></div>
      {isAuthenticated && user ? (
        <div className="profile-section" onClick={toggleDropdown}>
          {user.picture ? (
              <img
                src={user.picture}
                alt="Profile"
                className="profile-image"
              />
              ) : (
              <div className="profile-initial">
                {user.charAt(0).toUpperCase()}
              </div>
          )}

          {/* Dropdown menu */}
          {dropdownVisible && (
            <div className="dropdown-menu">
              <ul>
                <li>{user}</li>
                <li>Account</li>
                <li>Settings</li>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <button className="login-button">Login</button>
      )}
      </div>
    </nav>
  );
};

export default NavBar;
