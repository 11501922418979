import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { fetchChallengesDetails } from '../../services/ChallengesService';
import ChallengesTable from './ChallengesTable';
import FilterChallengeSidebar from './FilterChallengeSidebar'; // Import Filter Sidebar component

function TableSection() {
    const { isAuthenticated } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [challengesDetails, setChallengesDetails] = useState([]);
    const [filteredChallenges, setFilteredChallenges] = useState([]);
    const [filters, setFilters] = useState({ level: [], type: [], subtype: [] });
    const [numberOfResults, setNumberOfResults] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const resultsPerPage = 10; // Define how many results per page
    const [sortOption, setSortOption] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            const getChallengesDetails = async () => {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('User is not logged in');
                    return;
                }

                try {
                    const details = await fetchChallengesDetails(token);
                    setChallengesDetails(details);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                }
            };

            getChallengesDetails();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        let filteredResults = challengesDetails.filter(challenge => {
            const matchLevel = filters.level.length === 0 || filters.level.includes(challenge.level);
            const matchType = filters.type.length === 0 || filters.type.includes(challenge.type);
            const matchSubtype = filters.subtype.length === 0 || filters.subtype.includes(challenge.subtype);
            return matchLevel && matchType && matchSubtype;
        });

        // Apply search term
        if (searchTerm) {
            filteredResults = filteredResults.filter(challenge =>
                challenge.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Apply sorting
        if (sortOption) {
            filteredResults.sort((a, b) => {
                let valueA = a[sortOption];
                let valueB = b[sortOption];

                // Handle potential string values and null values
                valueA = (typeof valueA === 'string' ? valueA : '').toLowerCase();
                valueB = (typeof valueB === 'string' ? valueB : '').toLowerCase();

                if (valueA < valueB) return -1;
                if (valueA > valueB) return 1;
                return 0;
            });
        }

        setFilteredChallenges(filteredResults);
        setNumberOfResults(filteredResults.length);
        setCurrentPage(1); // Reset to first page whenever filter, search term, or sort option changes
    }, [filters, challengesDetails, searchTerm, sortOption]);

    const handlePageChange = (pageNumber) => {
        console.log("Page change requested:", pageNumber); // Add this log
        setCurrentPage(pageNumber);
    };

    const toIndexResults = Math.min(currentPage * resultsPerPage, numberOfResults);
    const currentResults = filteredChallenges.slice(
        (currentPage - 1) * resultsPerPage,
        toIndexResults
    );

    console.log("Current Page:", currentPage);
    console.log("Current Results:", currentResults);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <div className="main-container">
            <FilterChallengeSidebar filters={filters} setFilters={setFilters} />
            <div className="table-container">
                {loading ? (
                    <p>Loading Challenges Details...</p>
                ) : (
                    <ChallengesTable
                        challenges={currentResults}
                        currentPage={currentPage}
                        resultsPerPage={resultsPerPage}
                        onPageChange={handlePageChange}
                        totalResults={numberOfResults}
                        setSortOption={setSortOption}
                        setSearchTerm={setSearchTerm}
                    />
                )}
            </div>
        </div>
    );
}

export default TableSection;
